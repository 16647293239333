import React from "react";

const Footer = () => {
    return (
        <div className="footer">
            <p>Copyright @ Lauren Emick Design 2020</p>
        </div>
    );
};

export default Footer;