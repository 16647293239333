import React from "react";

const Contact = () => {

  return (
    <div id="contact" className="tab">
        <h1>Contact Section</h1>
    </div>
  );
};

export default Contact;